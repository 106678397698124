import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    ROUTE_ABOUT_US,
    ROUTE_ADVERTISE,
    ROUTE_ARTICLE_SLUG,
    ROUTE_COMPETITIONS,
    ROUTE_CONTACT_US,
    ROUTE_HOME,
    ROUTE_NEW_PRODUCTS,
    ROUTE_OTHER,
    ROUTE_TESTED_PRODUCTS,
    ROUTE_TIPS
} from '../common/routes';

// Controller components
import ArticleController from '../controllers/ArticleController';

// Page components
import Home from './pages/Home.js';
import AboutUs from './pages/AboutUs';
import Article from './pages/Article';
import ContactUs from './pages/ContactUs';
import Category from './pages/Category';
import Advertise from './pages/Advertise';

const fetchCategories = () => {
    return [
        { id: 1, name: 'senaste-nytt', route: ROUTE_HOME },
        { id: 2, name: 'testat', route: ROUTE_TESTED_PRODUCTS },
        { id: 3, name: 'prylnytt', route: ROUTE_NEW_PRODUCTS },
        { id: 4, name: 'tavling', route: ROUTE_COMPETITIONS },
        { id: 5, name: 'tips-och-rad', route: ROUTE_TIPS },
        // { id: 6, name: 'motionslopp', route: '/motionslopp'},
        // { id: 7, name: 'racer', route: '/racer'},
        // { id: 8, name: 'crosscountry-downhill-freeride', route: '/crosscountry-downhill-freeride'},
        { id: 10, name: 'ovrigt', route: ROUTE_OTHER },
    ];
};

const renderCategory = (category, route, id) => {
    const useExact = (route === '/') ? true : false;
    return (
        <Route exact={useExact} key={id} path={route}><Category category={category} /></Route>
    );
};

const renderCategories = (categories) => {
    return categories.map(cat => renderCategory(cat.name, cat.route, cat.id));
};

const Main = () => {
    const categories = fetchCategories();
    console.log('Categories: ', categories);

    const categoryComponents = renderCategories(categories);
    return (
        <div id="id-main-wrapper">
            <Switch>
                <Route exact path={ROUTE_HOME}><Home /></Route>
                <Route path={ROUTE_ABOUT_US}><AboutUs /></Route>
                <Route path={ROUTE_ADVERTISE}><Advertise /></Route>
                <Route path={ROUTE_ARTICLE_SLUG}><ArticleController><Article /></ArticleController></Route>
                <Route path={ROUTE_CONTACT_US}><ContactUs /></Route>
                {categoryComponents}
            </Switch>
        </div>
    );
};

export default Main;
