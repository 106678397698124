import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
// Reference: https://react-icons.github.io/react-icons/fa.html
import { FaAngleDown, FaAngleUp, FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';
import { ROUTE_ARTICLE_SLUG, ROUTEPART_SLUG, ROUTE_HOME } from '../common/routes';

import ArticleSamples from '../data/articles.json';

import DEF_IMG_01 from '../data/article-json/images/dvk1cowhhzkeg8xnhrqn.jpg';
import DEF_IMG_02 from '../data/article-json/images/Marc-Soler-MAGLIA-ALE-MOVISTAR-TEAM-2020.jpg';
import DEF_IMG_03 from '../data/article-json/images/adymo8kwrzo7n8xfjhpv.jpg';

const DEFAULT_IMAGES = [
    DEF_IMG_01,
    DEF_IMG_02,
    DEF_IMG_03,
];

const makeArticleUrl = article => {
    if (!article || !article.slug) {
        return ROUTE_HOME;
    }
    const articleUrl = ROUTE_ARTICLE_SLUG.replace(ROUTEPART_SLUG, article.slug);
    console.log('articleUrl: ', articleUrl);

    return articleUrl;
};

const ArticleFeed = ({categories}) => {
    const [articleCategories] = useState(categories);
    const [articles, setArticles] = useState([]);

    const renderFeaturedImage = (article) => {
        const image = article.featuredImage;
        if (!image) {
            return null;
        }

        const articleUrl = makeArticleUrl(article);
        const title = image.title;
        const alt = image.description;
        const id = article.id;
        const useDefaultId = (id >= 1 && id <= 3);
        const src = useDefaultId ? DEFAULT_IMAGES[id - 1] : image.src;

        return (
            <Link to={articleUrl}>
                <img src={src} title={title} alt={alt} />
            </Link>
        );
    };

    const renderArticleBody = (body) => {
        return (<div dangerouslySetInnerHTML={{ __html: body }}></div>);
    };

    const renderExcerpt = (article) => {
        const excerpt = article.excerpt;
        return (
            <span>{excerpt}</span>
        );
    };

    const renderShareSocial = (url) => {
        // References for brand colors:
        // https://brandpalettes.com/facebook-colors/
        // https://usbrandcolors.com/

        // Remove last character '/' since included in route url
        // const facebookShareUrl = 'http%3A%2F%2Ffacebook.com%2Fshare.php%3Fu%3D';
        const facebookShareUrl = 'https://facebook.com/share.php?u=';
        // const twitterShareUrl = 'http%3A%2F%2Ftwitter.com%2Fshare%3Furl%3D';
        const twitterShareUrl = 'https://twitter.com/share?url=';

        const facebookUrl = encodeURIComponent(`${facebookShareUrl}${url}`);
        const facebook = (
            <IconContext.Provider value={{ color: '#3b5998', className: 'social-facebook' }}>
                <FaFacebookSquare />
            </IconContext.Provider>
        );
        const twitterUrl = encodeURIComponent(`${twitterShareUrl}${url}`);
        const twitter = (
            <IconContext.Provider value={{ color: '#1DA1F2', className: 'social-facebook' }}>
                <FaTwitterSquare />
            </IconContext.Provider>
        );

        const makeSocialClickHandler = (url) => {
            const sharedUrl = url;
            const handleSocialClick = (e) => {
                console.log('Sharing through url: ', sharedUrl);
            }
            return handleSocialClick;
        };

        return (
            <div className="article-feed-social">
                <div className="article-share-header">Dela på:</div>
                <div className="article-share-social facebook" onClick={makeSocialClickHandler(facebookUrl)}>{facebook}</div>
                <div className="article-share-social twitter" onClick={makeSocialClickHandler(twitterUrl)}>{twitter}</div>
            </div>
        );
    };

    const embedInLink = (url, data) => {
        return (
            <Link to={url}>{data}</Link>
        );
    };

    const renderArticle = (article, filterCategories, includeBody = true) => {
        // TODO: Error checking that fields exist
        if (!article || !article.title || !article.body) {
            console.log('Invalid article data');
            return null;
        }
        // Only allow articles belonging to the filter
        const articleCategories = article.categories;
        const result = articleCategories.filter(cat => filterCategories.includes(cat));
        if (!result.length) {
            return null;
        }

        const id = article.id;
        const url = makeArticleUrl(article);
        const title = embedInLink(url, article.title);
        const featuredImage = renderFeaturedImage(article);
        const excerpt = includeBody ? null : embedInLink(url, renderExcerpt(article));
        const socialMedia = includeBody ? null : renderShareSocial(url);
        const body = includeBody ? embedInLink(url, renderArticleBody(article.body)) : null;

        return (
            <article key={id}>
                {featuredImage}
                <h1>{title}</h1>
                {excerpt}
                {body}
                {socialMedia}
            </article>
        );
    };

    const renderArticles = (articlesRaw, filterCategories) => {
        return articlesRaw.map(article => renderArticle(article, filterCategories, false));
    };

    const fetchArticles = async () => {
        console.log(ArticleSamples);
        // const fetchedArticles = JSON.parse(ArticleSamples);
        setArticles(ArticleSamples.articles);
        console.log(articles);
    };

    useEffect(() => {
        // Note that fetchArticles() is only called once
        // due to having an empty dependency array for useEffect()
        fetchArticles()
            .catch(error => console.error('Could not load articles', error));
        return (() => setArticles([]));
        // Keep comment below; gives warning on missing dependency for fetchArticles
        // eslint-disable-next-line
    }, []);

    return (
        <div className="article-wrapper">
            {renderArticles(articles, articleCategories)}
        </div>
    );
};

export default ArticleFeed;
