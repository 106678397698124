import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleUp, FaEnvelope, FaHome, FaInfoCircle } from 'react-icons/fa';
import { ROUTE_HOME, ROUTE_ABOUT_US, ROUTE_CONTACT_US } from '../common/routes';

const iconBaseClassNames = ['footer-icon'];

// Reference about icons, their names, images, etc
// https://react-icons.github.io/react-icons/fa.html
const iconDefsShared = {
    goBack: {
        id: 1,
        name: 'go-back',
        iconComponent: FaAngleLeft,
        classNames: [...iconBaseClassNames, 'footer-icon-back'],
        hidden: false
    },
    home: {
        id: 2,
        name: 'go-home',
        iconComponent: FaHome,
        classNames: [...iconBaseClassNames, 'footer-icon-home'],
        hidden: false
    },
    infoAboutUs: {
        id: 3,
        name: 'info-about-us',
        iconComponent: FaInfoCircle,
        classNames: [...iconBaseClassNames, 'footer-icon-info'],
        hidden: false
    },
    contactUs: {
        id: 4,
        name: 'contact-us',
        iconComponent: FaEnvelope,
        classNames: [...iconBaseClassNames, 'footer-icon-contact'],
        hidden: false
    },
    goTop: {
        id: 5,
        name: 'go-top',
        iconComponent: FaAngleUp,
        classNames: [...iconBaseClassNames, 'footer-icon-top'],
        hidden: false
    }
};

/** Footer Menu Icons
 * Every screen (page) can have its own footer menu icons.
 * It is recommended to keep "goBack" and "home" icons on
 * every screen.
 */

// Icons shown in footer menu on HOME (start) page
const homeScreenIconDefs = {
    goBack: Object.assign({},
        iconDefsShared.goBack,
        {
            pos: 1,
            hidden: true,
            route: null
        }),
    home: Object.assign({},
        iconDefsShared.home,
        {
            pos: 2,
            route: ROUTE_HOME
        }),
    infoAboutUs: Object.assign({},
        iconDefsShared.infoAboutUs,
        {
            pos: 3,
            route: ROUTE_ABOUT_US
        }),
    contactUs: Object.assign({},
        iconDefsShared.contactUs,
        {
            pos: 4,
            route: ROUTE_CONTACT_US
        }),
    goTop: Object.assign({},
        iconDefsShared.goTop,
        {
            pos: 5,
            route: null
        })
};

// Icons shown in footer menu on ABOUT US page
const aboutUsScreenIconDefs = Object.assign({}, homeScreenIconDefs);

// Icons shown in footer menu on CONTACT US page
const contactUsScreenIconDefs = Object.assign({}, homeScreenIconDefs);

const getIconDefsByRoute = (route) => {
    switch (route) {
        case ROUTE_ABOUT_US:
            return aboutUsScreenIconDefs;
        case ROUTE_HOME:
            return homeScreenIconDefs;
        case ROUTE_CONTACT_US:
            return contactUsScreenIconDefs;
        default:
            return homeScreenIconDefs;
    }
};

/** FooterMenuController
 * 
 * route = the route that determines which screen or page is currently displayed
 * children = usually one child of type FooterMenu, but can be any similar component
 * 
 * Example:
 *   <FooterMenuController>
 *      <FooterMenu />
 *   </FooterMenuController
 */
const FooterMenuController = ({ route, children }) => {
    const [footerMenuIconDefs, setFooterMenuIconDefs] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const makeOnClickIcon = useCallback((newRoute, stayOnPage = false) => (event) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        if (stayOnPage) {
            event.preventDefault();
            return false;
        }
        history.push(newRoute);
    }, [history, location]);

    useEffect(() => {
        // Route
        const iconDefs = getIconDefsByRoute(route);
        // Home click event
        if (!!iconDefs.home) {
            iconDefs.home.onClick = makeOnClickIcon(ROUTE_HOME);
        }
        // Info about us click event
        if (!!iconDefs.infoAboutUs) {
            iconDefs.infoAboutUs.onClick = makeOnClickIcon(ROUTE_ABOUT_US);
        }
        // Contact us click event
        if (!!iconDefs.contactUs) {
            iconDefs.contactUs.onClick = makeOnClickIcon(ROUTE_CONTACT_US);
        }
        // History
        if (!!iconDefs.goBack) {
            // Only show go-back icon at beginning of history
            if (history.length > 2) {
                // Opening a new browser tab counts as one before going to home
                iconDefs.goBack.hidden = false;
                iconDefs.goBack.onClick = (event) => history.goBack();
            } else {
                iconDefs.goBack.hidden = true;
                iconDefs.goBack.onClick = null;
            }
        }
        // Scroll to top
        if (!!iconDefs.goTop) {
            iconDefs.goTop.onClick = makeOnClickIcon(null, true);
        }

        setFooterMenuIconDefs(iconDefs);

        return () => setFooterMenuIconDefs(null);
    }, [route, history, makeOnClickIcon]);

    if (!footerMenuIconDefs) {
        return null;
    }
    return React.cloneElement(children, { iconDefinitions: footerMenuIconDefs });
};

export default FooterMenuController;
