import React from 'react';
import '../../assets/css/main-home.css';
import ArticleFeed from '../ArticleFeed';

const Home = () => {
    const categories = ['senaste-nytt'];

    return (
        <main id="id-main-home">
            <ArticleFeed categories={categories} />
            <div className="main-footer-filler"></div>
        </main>
    );
};

export default Home;
