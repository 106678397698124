import React, { useState } from 'react';
import '../../assets/css/main-article.css';

const renderContent = htmlContent => {
    return (<div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>);
};

const Article = ({content}) => {
    const [mainCssClassNames] = useState(`main-article`);
    const renderedContent = renderContent(content);

    return (
        <main className={mainCssClassNames}>
            <article className='single-article'>
            {renderedContent}
            </article>
            <div className="main-footer-filler"></div>
        </main>
    );
};

export default Article;
