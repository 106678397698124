import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/css/footer.css';
import FooterMenuController from '../controllers/FooterMenuController';
import FooterMenu from './FooterMenu';
import { ROUTE_ABOUT_US, ROUTE_ADVERTISE, ROUTE_CONTACT_US } from '../common/routes';

// See images of all react icons here:

const Footer = () => {
    const location = useLocation();

    const routeAboutUs = ROUTE_ABOUT_US;
    const infoAboutUsText = 'Klicka här för info om Cykelsajten!';

    const routeContactUs = ROUTE_CONTACT_US;
    const infoContactUsText = 'Klicka här för att kontakta Cykelsajten!';

    const routeSubscribe = '/gratis-nyhetsbrev';
    const infoSubscribeText = 'Klicka här för gratis nyhetsbrev!';

    const routeAdvertise = ROUTE_ADVERTISE;
    const infoAdvertiseText = 'Klicka här för att annonsera!';

    let footerInfoRoute = '/';
    let footerInfoText = '';
    if (location.pathname === routeAdvertise) {
        footerInfoRoute = routeContactUs;
        footerInfoText = infoContactUsText;
    } else {
        footerInfoRoute = routeAdvertise;
        footerInfoText = infoAdvertiseText;
    }

    return (
        <div id="id-footer-wrapper">
            <div id="id-footer-menu">
                <FooterMenuController route={location.pathname}>
                    <FooterMenu />
                </FooterMenuController>
            </div>
            <footer className="footer-info">
                <Link to={footerInfoRoute}>
                    {footerInfoText}
                </Link>
            </footer>
            <div className="black-filler-20vh"></div>
        </div>
    );
};

export default Footer;
