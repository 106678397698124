import React from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';

const DEF_ICON_COLOR = '#333';

const FooterMenuIcon = ({ iconComponent, classNameList = [], hidden = false, cssStyle = null, route = null, onClick = null, ...props }) => {
    if (!iconComponent) {
        return null;
    }
    const classNames = hidden
        ? `hidden ${classNameList.join(' ')}`
        : classNameList.join(' ');
    const style = !!cssStyle ? cssStyle : { color: DEF_ICON_COLOR };
    const iconProps = !!onClick ? { onClick: onClick, ...props } : props;
    const pureIconElement = React.createElement(iconComponent, iconProps);
    const linkedIconElement = !!route ? React.createElement(Link.type, props, pureIconElement) : null;
    const iconElement = !!linkedIconElement ? linkedIconElement : pureIconElement;

    return (
        <div className={classNames}>
            <IconContext.Provider value={style}>
                {iconElement}
            </IconContext.Provider>
        </div>
    );
};

export default FooterMenuIcon;
