import React from 'react';
import '../../assets/css/main-info.css';

const orgAdvertiseText = `Annonsera
<strong>Annonsera på Cykelsajten!</strong>
Snabbbt, enkelt och billigt når ni en väldigt fokuserad målgrupp som dagligen håller sig ajour med vad som händer inom cykling och som lägger både en anselig summa pengar  och mycket tid på cyklingen – där ett dagligt besök på Cykelsajten ingår :-)

Ni slipper deadlines och kan när som helst lansera en aktuell kampanj samt byta budskap flera gånger.  Cykelsajten erbjuder en 100 % -ig fullträff rätt in i solarplexus på en målgrupp som aktivt söker informationen dagligen.

<strong>Fördelar med Cykelsajten?</strong>
<ul><li>Inga deadlines – ni slipper att vänta tre månader på publicering</li>
<li>Snabba uppdateringar – besökarna får läsa nyheter – inte ”favorit i repris”, dvs gamla cykelnyheter</li></li>
<li>Kombination av testtexter och banner – 1 + 1 = 5</li>
<li>Vi är trovärdiga och har en extremt stor erfarenhet i ämnet</li>
<li>Hög daglig trafik – med en ständig tillväxt</li>
<li>Många unika besökare – med ständig tillväxt</li>
<li>Kunniga besökare som själva väljer att läsa om era produkter – när kunden själv får välja – ”accepted marketing”</li>
<li>Rätt målgrupp – som blir bredare och kunnigare med vår hjälp</li>
<li>Ökad vinst/försäljning för er</li>
</ul>

<strong>Advertorials</strong>
De flesta leverantörer har en bred flora av produkter som i stort sett aldrig marknadsförs eller omtalas i tester eller andra artiklar. Man har liten eller ingen kontroll alls över vad som skrivs om produkterna, när det skrivs och vad det skrivs.
Cykelsajten gör det möjligt för er att möta kunderna på en plats där aktivt söker efter information och råd om de aktuella produkterna.

Undersökningar visar att nätet används av 87 procent av de tillfrågade när de ska söka information om en vara eller produkt inför ett köp – har det gjorts några tester? vad tycker andra? osv – Något som verkligen talar för Cykelsajten

Bland dem som inte utgör målgruppens kärna är siffran vad vi erfar ännu högre, eller med andra ord; är man inte ett renodlat cykelfreak så köper man inte en cykeltidningen för ”dyra pengar” utan man letar där informationen är gratis = nätet = Cykelsajten

Samma sak gäller när det handlar om köp av begagnad cykelutrustning – informationen söks via nätet = Cykelsajten – vilket  statistiken för äldre tester på Cykelsajten visar.

I snitt har Cykelsajten cirka 15.000 unika besökare per månad; besökare som besöker oss allt från en gång per månad till flera gånger om dagen. Kort sagt; Cykelsajten har en intresserad och dedikerad målgrupp av besökare som är sajten trogen sedan flera år tillbaka – OCH den växer stadigt!

Cykelsajten erbjuder annonsering enligt följande format:

<strong>Annonser</strong>
En sk annons är betydligt större än en banner och läggs in i artiklarna där den syns oavsett vad besökaren gör. Givetvis klickbar dit ni önskar.
Format: enligt överrenskommelse men max bredd 800 pixlar.

<strong>Textlänkar</strong>
Är exakt vad det låter, en klickbar textlänk där ni erbjuds beskriva er produkt/erbjudande, tex; tramapaletto-skor, för 1995:- Nu 995:- Textlänkar erbjuder en väldigt hög ”genomklickningnivå” och är väldigt kostnadseffektiva. Placering : enligt överenskommelse.

<strong>Banners</strong>
Normalformatet för annonsering på webben. Finns i olika format och är klickbara dit ni vill. Animerade banners tillåts med som längst  en visningstid av fem (5) sekunder per ”face”. För storlekar och platser – se nedan.

<strong>Advertorials</strong>
Utöver vanlig ”exponering” på Cykelsajten kan vi i samband med annonsering på sajten erbjuda så kallade ”advertorials” om det görs en längre bokning av annonsering på Cykelsajten (minst 3 månader oavsett format). För pris begär offert.

 

<strong>ANNONSFORMAT</strong>

<strong>Toppbanner – syns alltid - Reklamplats på ytan som ligger högst upp på Cykelsajten</strong>
Kan vara en stor banner som täcker hela utrymmet eller delas av två eller tre mindre banners bredvid varandra. Är det bara en banner så visas den konstant hela tiden, annars delas utrymmet med övriga annonsörer, maximalt två andra, dvs totalt tre stycken kan visas rullande ”tre-om-tre”. Detta ger totalt nio annonsörer. Minsta bokning: 10.000 visningar eller en månad.
Format:
Hela utrymmet:  970 x 90   pixlar
Halva utrymmet:  460 x 90 pixlar
En tredjedel av utrymmet:  300 x 90 pixlar

<strong>Under ”toppbannern”, över ”höghusen” – till höger på sajten</strong>
Rullandes med max två andra annonser, dvs totalt tre stycken. Är det bara en annonsör så visas den konstant hela tiden.
Format:  600 x 90 pixlar

<strong>Längst till vänsterbanner – syns alltid</strong>
Är en kvadratisk banner under ”senaste nytt”.  Rullas med två andra annonser, dvs totalt tre stycken. Är det bara en annonsör så visas den konstant hela tiden.
Format: 180 x 180 pixlar

<strong>A-lägesbanner</strong>
Stor banner i mitten bland artiklarna – under de 4 första artiklarna
Rullandes med två andra annonser, dvs totalt tre stycken. Är det bara en annonsör så visas den konstant hela tiden.
Format: 600 x 90 pixlar

<strong>A-lägesbanner – mindre till höger – syns alltid</strong>
Mindre banner till höger bland ”mest lästa”  och”senaste nytt i korthet”
Rullandes med två andra annonser, Är det bara en annonsör så visas den konstant hela tiden.
Format: 380 x 230 pixlar

<strong>Höghusbanner – syns alltid</strong>
Längst till höger på hela sidan – står enskilt, rena annonser.
Placeras längst till höger om sajten. Bredden är förutbestämd men höjden avgörs av utformningen dock inom vissa ramar. Annonsen syns hela tiden.
Format: 300 x 300 pixlar

<strong>B-lägesbanner</strong>
Stor banner i mitten bland artiklarna – efter de 4 första artiklarna (a-läge)
Rullandes med två andra annonser. Är det bara en annonsör så visas den konstant hela tiden.
Format: 600 x 90 pixlar

<strong>C-lägesbanner</strong>
Annonserna ligger mellan artiklarna under ”förra veckans nyheter” och ”förrförra veckans nyheter”
Annonserna syns hela tiden, placeras så långt upp som möjligt i mån av plats.
Format: 600 x 90 pixlar

<strong>Dedikerad Banner</strong>
Banner som placeras på vald undersida t.ex. på sidan ”Testat”
Syns emellan artiklarna under vald kategori. Syns endast när besökaren läser en artikel i ämnesområdet, tex något i : Testat.
Format: 600 x90 pixlar, eller enligt överenskommelse.

<strong>Kopplad Banner</strong>
Banner som placeras i vald artikel och endast där. Kan även placeras i alla artiklar som läggs in under ett menyord; tex Testat.
Format: 600 x 90 pixlar, eller enligt överenskommelse.

<strong>Textlänkar</strong>
Olika placeringar på sajten enligt annonsörernas önskemål. Kan placeras i en bestämd artikel, eller generellt på sidan, eller endast på sidorna för vissa undermenyer, tex ”Testat".

<strong>Byte av banner under avtalstid</strong>
En kostnad på 300 kronor utgår per byte av banner under avtalstiden om inte annat är avtalat. Gäller även textlänkar och annonser.

<strong>Betalningsvillkor</strong>
På bokningar upp till tre månader; Faktura 20 dagar.

Bokningar 4 månader eller längre: 50 % faktura 20 dagar. Återstoden  betalas efter halva löptiden, dvs ett sexmånaderspaket betalas  med 50 % på 20 dagar samt återstoden efter 90 dagar.

25 % moms tillkommer på alla priser.

Vid avbokning av ingånget avtal debiteras 50 % av det överrenskommna priset.

Priset för annnonsering på Cykelsajten utgörs antingen av ett fast pris per annonsformat eller enligt branschstandarden per 1.000 visningar; CPT/CPM.

Prislista mejlas på begäran och vill ni ha en specialoffert för en längre/större bokning så är det bara att skicka ett mejl; wilhelm@Cykelsajten eller kontakta redaktionen.

Cykelsajten förbehåller sig rätten att neka annonsörer.

<strong>Cykelsajten</strong>
Olev AB
Grev Turegatan 10
Stockholm
Mobil: 0708-830 484

E-post
Försäljning: par@cykelsajten.se
Redaktion: par@cykelsajten.se
`;

const advIntro = {
    title: 'Annonsera på Cykelsajten!',
    paragraphs: [
        'Snabbbt, enkelt och billigt når ni en väldigt fokuserad målgrupp som dagligen håller sig ajour med vad som händer inom cykling och som lägger både en anselig summa pengar  och mycket tid på cyklingen – där ett dagligt besök på Cykelsajten ingår :-)',
        'Ni slipper deadlines och kan när som helst lansera en aktuell kampanj samt byta budskap flera gånger.  Cykelsajten erbjuder en 100 % -ig fullträff rätt in i solarplexus på en målgrupp som aktivt söker informationen dagligen.'
    ]
};
const advSection1 = {
    title: 'Fördelar med Cykelsajten',
    paragraphs: [
        'Inga deadlines – ni slipper att vänta tre månader på publicering',
        'Snabba uppdateringar – besökarna får läsa nyheter – inte ”favorit i repris”, dvs gamla cykelnyheter',
        'Kombination av testtexter och banner – 1 + 1 = 5',
        'Vi är trovärdiga och har en extremt stor erfarenhet i ämnet',
        'Hög daglig trafik – med en ständig tillväxt',
        'Många unika besökare – med ständig tillväxt',
        'Kunniga besökare som själva väljer att läsa om era produkter – när kunden själv får välja – ”accepted marketing”',
        'Rätt målgrupp – som blir bredare och kunnigare med vår hjälp',
        'Ökad vinst/försäljning för er'
    ]
};
const advSection2 = {
    title: 'Advertorials',
    paragraphs: [
        'De flesta leverantörer har en bred flora av produkter som i stort sett aldrig marknadsförs eller omtalas i tester eller andra artiklar. Man har liten eller ingen kontroll alls över vad som skrivs om produkterna, när det skrivs och vad det skrivs.',
        'Cykelsajten gör det möjligt för er att möta kunderna på en plats där aktivt söker efter information och råd om de aktuella produkterna.',
        'Undersökningar visar att nätet används av 87 procent av de tillfrågade när de ska söka information om en vara eller produkt inför ett köp – har det gjorts några tester? vad tycker andra? osv – Något som verkligen talar för Cykelsajten',
        'Bland dem som inte utgör målgruppens kärna är siffran vad vi erfar ännu högre, eller med andra ord; är man inte ett renodlat cykelfreak så köper man inte en cykeltidningen för ”dyra pengar” utan man letar där informationen är gratis = nätet = Cykelsajten',
        'Samma sak gäller när det handlar om köp av begagnad cykelutrustning – informationen söks via nätet = Cykelsajten – vilket  statistiken för äldre tester på Cykelsajten visar.',
        'I snitt har Cykelsajten cirka 15.000 unika besökare per månad; besökare som besöker oss allt från en gång per månad till flera gånger om dagen. Kort sagt; Cykelsajten har en intresserad och dedikerad målgrupp av besökare som är sajten trogen sedan flera år tillbaka – OCH den växer stadigt!'
    ]
};

const CSS_CLASS_BODY_TEXT = 'body-text';
const CSS_CLASS_HEADLINE = 'headline';

const renderTitle = (headline, ...classNameList) => {
    const classNames = !!classNameList ? classNameList.join(' ') : null;

    return (
        <h1 className={classNames}>{headline}</h1>
    );
};

const renderMultiParagraphs = (paragraphs, ...classNameList) => {
    const classNames = !!classNameList ? classNameList.join(' ') : null;

    return paragraphs.map((item, index) => (
        <p key={index} className={classNames}>
            {item}
        </p>
    ))
};

const Advertise = () => {
    const advIntroTitle = renderTitle(advIntro.title, CSS_CLASS_HEADLINE);
    const advIntroParagraphs = renderMultiParagraphs(advIntro.paragraphs, CSS_CLASS_BODY_TEXT);
    const advSection1Title = renderTitle(advSection1.title, CSS_CLASS_HEADLINE);
    const advSection1Paragraphs = renderMultiParagraphs(advSection1.paragraphs, CSS_CLASS_BODY_TEXT);
    const advSection2Title = renderTitle(advSection2.title, CSS_CLASS_HEADLINE);
    const advSection2Paragraphs = renderMultiParagraphs(advSection2.paragraphs, CSS_CLASS_BODY_TEXT);

    return (
        <main id='id-main-advertise' className='main-info'>
            {advIntroTitle}
            {advIntroParagraphs}
            {advSection1Title}
            {advSection1Paragraphs}
            {advSection2Title}
            {advSection2Paragraphs}
            <div class="main-footer-filler"></div>
        </main>
    );
};

export default Advertise;
