import React from 'react';
import '../../assets/css/main-info.css';

const orgAboutText = `Cykelsajten.se
Cykelsajten är en helt oberoende sajt med inriktning på cykling i alla dess former. Vi ger råd, tips och information om det mesta som rör sig i cykelvärldens olika discipliner.

Cykelsajtens främsta målsättning är att tillställa besökarna trovärdig och objektiv information om tävlingar, events, produkter, och allt annat som kan anses vara förenligt med cyklingen och dess omvärld. Ett av våra populäraste och mest lästa område är tester av cyklar och alla de olika tillbehör som berikar cykelvärlden.

<strong>Målgrupp</strong>
I och med vår nylansering av Cykelsajten – Sveriges äldsta webbtidning om cykling så kommer även sajtens målsättning och målgrupp att ändras. Det är inte semisponsrade nedbantade elitcyklister med tunn plånbok som är sajtens målgrupp utan den kader av informationshungriga nybörjarmotionister som väller ut på våra vägar iklädda fel sorts underställ (en bomullströja) med en fladdrande skaljacka (skidjackan i gore-tex) sittandes på en alltför liten cykel (den var nedsatt med 25 %) körandes på tyngsta växeln (med en tyngre växel går det ju fortare).

Och som nybörjare börjar man med att söka information på nätet! Vi har tester gjorda för så länge sedan som 2006 och som fortfarande läses av våra besökare!! En cykel säljs begagnad och den presumtiva köparen googlar modellnamnet och voila! Cykelsajten kommer upp i sökrutan.

Det är bland dessa nyborna motionister som Cykelsajten har ett uppdrag att fylla:
Att hjälpa dem till en bättre och roligare cykling
Att visa dem att det finns fler motionslopp att cykla än Vätternrundan och CykelVasan
Att få dem att undgå nybörjarfelen
Att ge dem kunskap och information så att de vet vad de ska köpa när det är dags för ny cykel eller alla de tillbehör som omgärdar cyklandet.

Utöver detta blir det träningstips, prylnyheter, resereportage, konsumentupplysning, t.ex. Cykelsajten reder ut försäkringsdjungeln, racerclinics där vi bjuder in kända föredragshållare samtidigt som vi lär motionisterna genvägarna till snabbare och bättre cykling plus allt det där som gör cykling till vad cykling är och kan vara.

<strong>Portföljracing</strong>

Det dagliga pendlandet ökar, speciellt i storstäderna och vi känner att det finns behov av några webbsidor som behandlar ämnet och där våra besökare kan meddela hur deras kommun sköter det här med cykelbanor och dylikt.

Som besökare är du välkommen att bidra med text och bild. Kom bara ihåg att vi inte ansvarar för inskickat material. Skickar du in material för publicering på Cykelsajten utgår vi ifrån att det är fritt att använda och vi friskriver oss från alla eventuella anspråk på copyrighter, bildrättigheter och dylikt som kan uppstå i efterhand av tredje part.

Har ni tips och synpunkter på velo.se´s innehåll, urval av texter och information samt andra önskemål om vad vi ska skriva om? Hör av Er till: par@cykelsajten.se
`;

const aboutIntro = {
    title: 'Cykelsajten',
    paragraphs: [
        'Cykelsajten är en helt oberoende sajt med inriktning på cykling i alla dess former. Vi ger råd, tips och information om det mesta som rör sig i cykelvärldens olika discipliner.',
        'Cykelsajtens främsta målsättning är att tillställa besökarna trovärdig och objektiv information om tävlingar, events, produkter, och allt annat som kan anses vara förenligt med cyklingen och dess omvärld. Ett av våra populäraste och mest lästa område är tester av cyklar och alla de olika tillbehör som berikar cykelvärlden.'
    ]
};
const aboutSection1 = {
    title: 'Målgrupp',
    paragraphs: [
        'I och med vår nylansering av Cykelsajten – Sveriges äldsta webbtidning om cykling så kommer även sajtens målsättning och målgrupp att ändras. Det är inte semisponsrade nedbantade elitcyklister med tunn plånbok som är sajtens målgrupp utan den kader av informationshungriga nybörjarmotionister som väller ut på våra vägar iklädda fel sorts underställ (en bomullströja) med en fladdrande skaljacka (skidjackan i gore-tex) sittandes på en alltför liten cykel (den var nedsatt med 25 %) körandes på tyngsta växeln (med en tyngre växel går det ju fortare).',
        'Och som nybörjare börjar man med att söka information på nätet! Vi har tester gjorda för så länge sedan som 2006 och som fortfarande läses av våra besökare!! En cykel säljs begagnad och den presumtiva köparen googlar modellnamnet och voila! Cykelsajten kommer upp i sökrutan.',
        'Det är bland dessa nyborna motionister som Cykelsajten har ett uppdrag att fylla:',
        'Att hjälpa dem till en bättre och roligare cykling',
        'Att visa dem att det finns fler motionslopp att cykla än Vätternrundan och CykelVasan',
        'Att få dem att undgå nybörjarfelen',
        'Att ge dem kunskap och information så att de vet vad de ska köpa när det är dags för ny cykel eller alla de tillbehör som omgärdar cyklandet.',
        'Utöver detta blir det träningstips, prylnyheter, resereportage, konsumentupplysning, t.ex. Cykelsajten reder ut försäkringsdjungeln, racerclinics där vi bjuder in kända föredragshållare samtidigt som vi lär motionisterna genvägarna till snabbare och bättre cykling plus allt det där som gör cykling till vad cykling är och kan vara.'
    ]
};
const aboutSection2 = {
    title: 'Portföljracing',
    paragraphs: [
        'Det dagliga pendlandet ökar, speciellt i storstäderna och vi känner att det finns behov av några webbsidor som behandlar ämnet och där våra besökare kan meddela hur deras kommun sköter det här med cykelbanor och dylikt.',
        'Som besökare är du välkommen att bidra med text och bild. Kom bara ihåg att vi inte ansvarar för inskickat material. Skickar du in material för publicering på Cykelsajten utgår vi ifrån att det är fritt att använda och vi friskriver oss från alla eventuella anspråk på copyrighter, bildrättigheter och dylikt som kan uppstå i efterhand av tredje part.',
        'Har ni tips och synpunkter på velo.se´s innehåll, urval av texter och information samt andra önskemål om vad vi ska skriva om? Hör av Er till: par@cykelsajten.se'
    ]
};

const CSS_CLASS_BODY_TEXT = 'body-text';
const CSS_CLASS_HEADLINE = 'headline';

const renderTitle = (headline, ...classNameList) => {
    const classNames = !!classNameList ? classNameList.join(' ') : null;

    return (
        <h1 className={classNames}>{headline}</h1>
    );
};

const renderMultiParagraphs = (paragraphs, ...classNameList) => {
    const classNames = !!classNameList ? classNameList.join(' ') : null;

    return paragraphs.map((item, index) => (
        <p key={index} className={classNames}>
            {item}
        </p>
    ))
};

const AboutUs = () => {
    const aboutIntroTitle = renderTitle(aboutIntro.title, CSS_CLASS_HEADLINE);
    const aboutIntroParagraphs = renderMultiParagraphs(aboutIntro.paragraphs, CSS_CLASS_BODY_TEXT);
    const aboutSection1Title = renderTitle(aboutSection1.title, CSS_CLASS_HEADLINE);
    const aboutSection1Paragraphs = renderMultiParagraphs(aboutSection1.paragraphs, CSS_CLASS_BODY_TEXT);
    const aboutSection2Title = renderTitle(aboutSection2.title, CSS_CLASS_HEADLINE);
    const aboutSection2Paragraphs = renderMultiParagraphs(aboutSection2.paragraphs, CSS_CLASS_BODY_TEXT);

    return (
        <main id='id-main-about-us' className='main-info'>
            {aboutIntroTitle}
            {aboutIntroParagraphs}
            {aboutSection1Title}
            {aboutSection1Paragraphs}
            {aboutSection2Title}
            {aboutSection2Paragraphs}
            <div className="main-footer-filler"></div>
        </main>
    );
};

export default AboutUs;
