// Routes which are used to access client-internal URLs, used by single-page apps
export const ROUTE_INVALID = null
    , ROUTE_ABOUT_US = '/info-om-oss'
    , ROUTE_ADVERTISE = '/annonsera'
    , ROUTE_ARTICLE_SLUG = '/artikel/:slug'
    , ROUTE_COMPETITIONS = '/tavlingar'
    , ROUTE_CONTACT_US = '/kontakta-oss'
    , ROUTE_HOME = '/'
    , ROUTE_NEW_PRODUCTS = '/prylnytt'
    , ROUTE_OTHER = '/ovrigt'
    , ROUTE_TESTED_PRODUCTS = '/testat'
    , ROUTE_TIPS = '/tips-och-rad';

// parts of a route (part of route string between two '/' or after last '/')
export const ROUTEPART_SLUG = ':slug';