import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleSamples from '../data/articles.json';

const ARTICLE_NOT_FOUND_TEXT = 'Artikeln kunde inte hittas!';

const calcSlugHash = slug => {
    return 1;
};

const slugLookup = new Map(ArticleSamples.articles.map(article =>
    [calcSlugHash(article.slug), article.slug]));

const fetchArticleContent = articleSlug => {
    const articleContent = ArticleSamples.articles.find(article => article.slug === articleSlug);
    if (!articleContent) {
        return `<h1>${ARTICLE_NOT_FOUND_TEXT}</h1>`;
    }
    console.log(articleContent);
    return `<h1>${articleContent.title}</h1>${articleContent.body}`;
};

const fetchArticleContent2 = articleSlug => {
    const articleContent = ArticleSamples.articles.find(article => {
        if (article.slug === articleSlug) {
            return `<h1>ARTICLE CONTENT</h1><p>FOUND...</p><p>${articleSlug}</p>`;
        }
    });
    if (!articleContent) {
        return `<h1>${ARTICLE_NOT_FOUND_TEXT}</h1>`;
    }
    console.log(articleContent);
    return articleContent;
};

const ArticleController = ({ children }) => {
    const [articleContent, setArticleContent] = useState(null);
    const { slug } = useParams();
    console.log('articleSlug: ', slug);

    useEffect(() => {
        setArticleContent(fetchArticleContent(slug));

        return () => setArticleContent(null);
    }, [slug]);

    return React.cloneElement(children, { content: articleContent });;
};

export default ArticleController;