import React from 'react';
import '../assets/css/app.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';

const App = () => {
  return (
    <div id="id-app">
      <Router>
        <Header />
        <Main />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
