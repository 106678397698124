import React from 'react';

const contactIntro = {
    title: 'Kontakta oss på Cykelsajten',
    paragraphs: [
        'Skicka ett email till följande adress:',
        'info@cykelsajten.se'
    ]
};

const CSS_CLASS_BODY_TEXT = 'body-text';
const CSS_CLASS_HEADLINE = 'headline';

const renderTitle = (headline, ...classNameList) => {
    const classNames = !!classNameList ? classNameList.join(' ') : null;

    return (
        <h1 className={classNames}>{headline}</h1>
    );
};

const renderMultiParagraphs = (paragraphs, ...classNameList) => {
    const classNames = !!classNameList ? classNameList.join(' ') : null;

    return paragraphs.map((item, index) => (
        <p key={index} className={classNames}>
            {item}
        </p>
    ))
};

const ContactUs = () => {
    const contactIntroTitle = renderTitle(contactIntro.title, CSS_CLASS_HEADLINE);
    const contactIntroParagraphs = renderMultiParagraphs(contactIntro.paragraphs, CSS_CLASS_BODY_TEXT);

    return (
        <main id='id-main-contact-us' className='main-info'>
            {contactIntroTitle}
            {contactIntroParagraphs}
            <div className="main-footer-filler"></div>
        </main>
    );
};

export default ContactUs;
