import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/header.css';
import logo from '../assets/images/cykelsajten-logo.png';
import MenuBar from './MenuBar';

const renderHeaderLogo = () => {
    const logoAltText = 'logo for Cykelsajten.se';

    return (
        <div id="id-header-wrapper">
            <div id="id-header-logo">
                <Link to="/">
                    <img src={logo} alt={logoAltText} />
                </Link>
            </div>
        </div>
    );
};

const Header = () => {
    const headerLogo = renderHeaderLogo();

    return (
        <header className="app-header">
            {headerLogo}
            <MenuBar />
        </header>
    );
};

export default Header;
