import React from 'react';
import { Link } from 'react-router-dom';
import {
    ROUTE_TESTED_PRODUCTS,
    ROUTE_NEW_PRODUCTS,
    ROUTE_COMPETITIONS,
    ROUTE_TIPS,
    ROUTE_OTHER
} from '../common/routes';
import '../assets/css/menu-bar.css';

const defaultMenuItems = [
    { id: 1, caption: 'Testat', url: ROUTE_TESTED_PRODUCTS },
    { id: 2, caption: 'Prylnytt', url: ROUTE_NEW_PRODUCTS },
    { id: 3, caption: 'Tävling', url: ROUTE_COMPETITIONS },
    { id: 4, caption: 'Tips', url: ROUTE_TIPS },
    { id: 5, caption: 'Övrigt', url: ROUTE_OTHER }
];

const renderMenuItem = (menuItem) => {
    return (
        <li key={menuItem.id}>
            <Link to={menuItem.url}>
                {menuItem.caption}
            </Link>
        </li>
    );
};

const MenuBar = () => {

    const renderedMenuItems = defaultMenuItems.map(item => renderMenuItem(item));

    return (
        <div id="id-menu-bar">
            <ul>
                {renderedMenuItems}
            </ul>
        </div>
    );
};

export default MenuBar;
