import React, { useState } from 'react';
import '../../assets/css/main-category.css';
import ArticleFeed from '../ArticleFeed';

const Category = ({category}) => {
    const [categories] = useState([category]);
    const [mainCssClassNames] = useState(`main-category cat-${category}`);

    return (
        <main className={mainCssClassNames}>
            <ArticleFeed categories={categories} />
            <div className="main-footer-filler"></div>
        </main>
    );
};

export default Category;
