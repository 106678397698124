import React, { useState } from 'react';
import FooterMenuIcon from './FooterMenuIcon';

/**
 * iconDefinitions = ordered array, each element has the style, icon-type, etc
 *   for a corresponding <FooterMenuIcon> element's settings.
 * 
 * Example of what an element in iconDefinitions[] can look like:
 * 
 * goBack: {
 *     id: 1,
 *     name: 'go-back',
 *     pos: 1,
 *     hidden: true,
 *     icon: FaAngleLeft,
 *     route: null
 * }
 * 
 * <FooterMenuIcons>
 *   <FooterMenuIcon options={} />
 *   <FooterMenuIcon options={} />
 *     ...
 *   <FooterMenuIcon options={} />
 * </FooterMenuIcons>
 */
const FooterMenu = ({ iconDefinitions }) => {
    const [iconDefLookup] = useState(iconDefinitions);
    if (!iconDefinitions) {
        return null;
    }

    const orderedIconDefsKeys = [];
    Object.keys(iconDefinitions).forEach(key => {
        orderedIconDefsKeys.push({ pos: iconDefinitions[key].pos, key: key });
    });
    orderedIconDefsKeys.sort((item1, item2) => (item1.pos - item2.pos));

    const renderedFooterMenuIcons = orderedIconDefsKeys.map(({key}) => {
        const item = iconDefLookup[key];
        return (
            <FooterMenuIcon key={item.id}
                iconComponent={item.iconComponent}
                classNameList={item.classNames}
                hidden={item.hidden}
                onClick={item.onClick}
                cssStyle={item.style} />
        )
    });

    return (
        <div className='footer-menu'>
            <div id="id-footer-menu-icons">
                {renderedFooterMenuIcons}
            </div>
        </div>
    );
};

export default FooterMenu;
